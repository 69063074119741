<template>
    <section class="main-content is-transparent">
        <div class="hero">
            <div class="hero-body centered-body has-text-centered">
                <h1
                    class="title is-1 is-uppercase has-text-primary has-text-weight-bold"
                >
                    {{ $t("notfound.title") }}
                </h1>

                <h2 class="subtitle is-4 is-subtle has-text-weight-normal">
                    {{ $t("notfound.description") }}
                </h2>
                <nuxt-link :to="localePath('/')">
                    <b-button
                        icon-left="fas fa-chevron-left"
                        type="is-large"
                        class="button has-icon-right is-subtle px-5"
                    >
                        {{ $t("notfound.back") }}
                    </b-button>
                </nuxt-link>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
</style>
<script>
export default {
    name: "NotFound",
    data() {
        return {
            pageTitle: "TDF*P — " + this.$t("notfound.meta.title"),
        };
    },

    watch: {
        $route(to, from) {
            this.$store.commit("setCurrPageTitle", this.$t("notfound.header"));
        },
    },
    mounted() {
        this.$store.commit("setCurrPageTitle", this.$t("notfound.header"));

        this.enterAnimation();
    },
    head() {
        return {
            title: this.pageTitle,
        };
    },
    layout: "default",
    methods: {
        enterAnimation() {
            const anime = this.$anime;
            anime({
                targets: ".main-content",
                easing: "easeInOutQuad",
                opacity: [0, 1],
                filter: ["blur(2rem)", "blur(0px)"],
                duration: 360,
            });
        },
    },
};
</script>
